import { ArrowBack, DateRange, EventBusy, Today } from "@mui/icons-material";
import { Alert, AlertTitle, Autocomplete, Box, Button, CardActionArea, Container, Grid, Paper, Stack, TextField, Typography } from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { fetchGet, fetchPost, isAndroid, isMobile, LayoutContext } from "wcz-layout";
import { BottomDrawerScanner } from "wcz-scan";
import NfcTypography from "../../components/common/NfcTypography";
import StyledCard from "../../components/common/StyledCard";
import { ScheduleType } from "../../models/enums/ScheduleType";
import Maintenance from "../../models/Maintenance";
import WrittenTag from "../../models/WrittenTag";
import { pmtcUrl } from "../../utils/BaseUrl";
import { isNfcAvailable, readNfc } from "../../utils/Nfc";
import Device from "../../models/Device";
import { trimEnd } from "lodash";
import { DepartmentContext } from "../../contexts/DepartmentContext";

export default function CreateMaintenance() {
    const [scheduleType, setScheduleType] = useState<string>("");
    const { changeTitle, t, i18n, user } = useContext(LayoutContext);
    const [device, setDevice] = useState<Device | null>(null);
    const navigate = useNavigate();
    const { department } = useContext(DepartmentContext);

    useEffect(() => changeTitle(t("Maintenance")), [i18n.language]);

    const { data: devices = [] } = useQuery<Device[]>(["devices"], ({ signal }) => fetchGet(`${pmtcUrl}/v1/device${department ? `?department=${trimEnd(department, "0")}` : ""}`, signal), {
        enabled: !!user.department && !isMobile,
        select: data => data.filter(device => device.tagType === "None")
    });

    useEffect(() => {
        const ctrl = new AbortController();

        if (isNfcAvailable)
            readNfc({
                onSuccess: value => onResult(value),
                options: { signal: ctrl.signal }
            });

        return () => ctrl.abort();
    }, []);

    const { mutate } = useMutation((deviceId: string) => fetchPost(`${pmtcUrl}/v1/maintenance/device/${deviceId}/scheduleType/${scheduleType}`, {}), {
        onSuccess: (data: Maintenance) => {
            navigate("/maintenance/device/" + data.device.id);
        }
    });

    const scheduleTypeSelect = (
        <Grid container spacing={1} sx={{ px: 1, my: 1 }}>
            <StyledCard title={t("Daily")} onClick={() => setScheduleType(ScheduleType.DAILY)}><Today sx={{ mt: 1 }} /></StyledCard>
            <StyledCard title={t("Weekly")} onClick={() => setScheduleType(ScheduleType.WEEKLY)}><DateRange sx={{ mt: 1 }} /></StyledCard>
            <StyledCard title={t("Monthly")} onClick={() => setScheduleType(ScheduleType.MONTHLY)}><DateRange sx={{ mt: 1 }} /></StyledCard>
            <StyledCard title={t("Yearly")} onClick={() => setScheduleType(ScheduleType.YEARLY)}><DateRange sx={{ mt: 1 }} /></StyledCard>
            <StyledCard title={t("Never")} onClick={() => setScheduleType(ScheduleType.NEVER)}><EventBusy sx={{ mt: 1 }} /></StyledCard>
        </Grid>
    );

    const onResult = useCallback((value: string) => {
        const writtenTag: WrittenTag = JSON.parse(value);
        if (writtenTag.id)
            mutate(writtenTag.id);
    }, []);

    const scanDeviceId = (
        <React.Fragment>
            <Grid container spacing={1} sx={{ px: 1, mt: 1 }}>
                <Grid item xs={12}>
                    <Paper variant="outlined" onClick={() => setScheduleType("")}>
                        <CardActionArea>
                            <Typography variant="h5" sx={{ m: 1, display: 'flex', alignItems: 'center', flexWrap: 'wrap', }}><ArrowBack sx={{ mr: 3 }} />{t(scheduleType)}</Typography>
                        </CardActionArea>
                    </Paper>
                </Grid>

                {isAndroid &&
                    <Grid item xs={12}>
                        <NfcTypography />
                    </Grid>
                }
            </Grid>

            {isMobile && <BottomDrawerScanner onResult={onResult} />}
            {!isMobile &&
                <Container maxWidth="xs" sx={{ mt: 5 }}>
                    <Stack spacing={2}>
                        <Autocomplete
                            value={device}
                            options={devices}
                            getOptionLabel={option => option.name}
                            autoHighlight
                            onChange={(e, value) => setDevice(value)}
                            fullWidth
                            renderInput={(params) => <TextField {...params} fullWidth label={t("Device")} autoFocus />}
                        />
                    </Stack>

                    <Button variant="contained" sx={{ mt: 2, float: 'right' }} disabled={!device} onClick={() => mutate(device!.id)}>{t("Create")}</Button>
                </Container>
            }
        </React.Fragment>
    );

    return (
        <Container>
            <Alert severity="warning" variant="filled" sx={{ mx: 1, mt: 1 }}>
                <AlertTitle>{t("UnscheduledMaintenance")}</AlertTitle>
                {t("UnscheduledMaintenanceDescription")}
            </Alert>

            {!scheduleType && scheduleTypeSelect}
            {scheduleType && scanDeviceId}
        </Container>
    );
}