import { AppBar, Box, Button, Dialog, DialogActions, DialogContent, Grid, Pagination, Tab, Tabs } from "@mui/material";
import { isEmpty } from "lodash";
import { useCallback, useContext, useMemo, useState } from "react";
import { LayoutContext } from "wcz-layout";
import Category from "../../../models/Category";
import Template from "../../../models/Template";
import LoadingButton from "../../common/LoadingButton";
import Scheduler from "./templateDialog/Scheduler";
import TemplateTasks from "./templateDialog/TemplateTasks";

interface TemplateDialogProps {
    open: boolean,
    onClose: () => void,
    template: Template,
    setTemplate: (template: Template) => void,
    onSubmit: () => void,
    categories: Category[]
}

export default function TemplateDialog(props: TemplateDialogProps) {
    const { open, onClose, template, setTemplate, onSubmit, categories } = props;
    const [tab, setTab] = useState(0);
    const [page, setPage] = useState<number>(1);
    const { t } = useContext(LayoutContext);

    const totalPages = Math.ceil((template.tasks?.length ?? 0) / 10);

    const handlePageChange = useCallback((event: React.ChangeEvent<unknown>, value: number) => setPage(value), []);

    const disabledSave: boolean = useMemo(() => isEmpty(template.category) || !template.scheduleType || !template.tasks.filter(task => !!task.text).length, [template]);

    const handleOnClose = useCallback(() => {
        onClose();
        setPage(1);
    }, []);

    return (
        <Dialog open={open} onClose={handleOnClose} maxWidth="md" fullWidth>
            <AppBar position="static">
                <Tabs value={tab} centered textColor="inherit" indicatorColor="secondary">
                    <Tab label={t("Schedule")} onClick={() => setTab(0)} />
                    <Tab label={t("Tasks")} onClick={() => setTab(1)} />
                </Tabs>
            </AppBar>

            <DialogContent dividers>
                <div hidden={!!tab}>
                    <Scheduler template={template} setTemplate={setTemplate} categories={categories} />
                </div>

                <div hidden={!tab}>
                    <TemplateTasks tasks={template.tasks} setTasks={tasks => setTemplate({ ...template, tasks: tasks })} page={page} setPage={setPage} />
                </div>
            </DialogContent>

            <DialogActions>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} justifyContent="center">
                        {(totalPages > 1 && !!tab) &&
                            <Box sx={{ display: { xs: "flex", sm: "inherit" }, justifyContent: { xs: "center", sm: "inherit" } }}>
                                <Pagination count={totalPages} page={page} onChange={handlePageChange} shape="rounded" />
                            </Box>
                        }
                    </Grid>

                    <Grid item xs={12} sm={6} textAlign="end">
                        <Button color="inherit" onClick={handleOnClose} sx={{ mr: 1 }}>{t("Close")}</Button>
                        <LoadingButton variant="contained" onClick={onSubmit} disabled={disabledSave}>{t("Save")}</LoadingButton>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
}