import { History } from '@mui/icons-material';
import { DataGridPremium, GridActionsCellItem, GridColDef, GridRowModel } from "@mui/x-data-grid-premium";
import { useQuery } from "@tanstack/react-query";
import { trimEnd } from 'lodash';
import { Fragment, useContext, useEffect, useMemo, useState } from "react";
import { LayoutContext, fetchGet, newGuid } from "wcz-layout";
import { AutocompleteEditInputCell, ChipInputCell, EditableHeader, GridDeleteCellItem, GridToolbar, GridToolbarProps, TableContainer, editInputCellValidation } from "wcz-x-data-grid";
import CategoryTrail from "../components/category/CategoryTrail";
import { DepartmentContext } from '../contexts/DepartmentContext';
import Category from "../models/Category";
import CategoryDepartment from '../models/CategoryDepartment';
import { useCreateCategory, useDeleteCategory, useUpdateCategory } from '../services/CategoryService';
import { peoplesoftUrl, pmtcUrl } from "../utils/BaseUrl";

export default function Categories() {
    const [departments, setDepartments] = useState([] as string[]);
    const [trailId, setTrailId] = useState<string>("");
    const { changeTitle, user, t, i18n } = useContext(LayoutContext);
    const { department } = useContext(DepartmentContext);

    const { data: categories = [], isLoading } = useQuery<Category[]>(["categories"], ({ signal }) => fetchGet(`${pmtcUrl}/v1/category${department ? `?department=${trimEnd(department, "0")}` : ""}`, signal), {
        enabled: !!user.department
    });

    useQuery(["departments"], ({ signal }) => fetchGet(`${peoplesoftUrl}/v1/department/all`, signal), {
        onSuccess: (data) => setDepartments(data.map((i: any) => i.departmentId).sort()),
    });

    useEffect(() => changeTitle(t("Categories")), [i18n.language]);

    const { mutateAsync: create } = useCreateCategory();
    const { mutateAsync: update } = useUpdateCategory();
    const remove = useDeleteCategory();

    const columns: GridColDef[] = useMemo(() => [
        {
            field: 'id', type: 'actions', width: 50,
            getActions: (params: any) => [
                <GridActionsCellItem key="history" icon={<History />} label={t("History")} onClick={() => setTrailId(params.id)} showInMenu />,
                <GridDeleteCellItem key="remove" id={params.id} remove={remove} showInMenu />
            ],
        },
        { field: 'name', headerName: t("Name"), width: 200, editable: true, preProcessEditCellProps: editInputCellValidation.hasLength, renderHeader: EditableHeader, },
        {
            field: 'departments', headerName: t("Departments"), width: 300, flex: 1, renderHeader: EditableHeader, preProcessEditCellProps: editInputCellValidation.hasLength, editable: true,
            valueGetter: ({ value }) => value?.map((v: any) => v.value) ?? [],
            renderCell: params => <ChipInputCell params={params} />,
            renderEditCell: params => <AutocompleteEditInputCell params={params} options={departments} multiple />,
        },
    ] as GridColDef[], [departments, i18n.language]);

    const processRowUpdate = async (row: GridRowModel) => {
        const oldRow = categories.find(c => c.id === row.id);

        const previousDepartments = oldRow?.departments.map(d => d.value) || [];

        const newDepartments = row.departments
            .filter((department: string) => !previousDepartments.includes(department))
            .map((department: string) => { return { id: newGuid(), value: department } as CategoryDepartment; });

        const deletedDepartments = previousDepartments.filter((department: string) => !row.departments.includes(department));

        const departments = (oldRow?.departments.filter(department => !deletedDepartments.includes(department.value)) || []).concat(newDepartments);

        const updatedRow: any = { ...row, departments: departments };

        if (updatedRow.isNew)
            await create(updatedRow);
        else
            await update(updatedRow);

        return { ...updatedRow, isNew: false };
    };

    return (
        <Fragment>
            <TableContainer>
                <DataGridPremium rows={categories} columns={columns} slots={{ toolbar: GridToolbar }} loading={isLoading} editMode="row" processRowUpdate={processRowUpdate}
                    slotProps={{ toolbar: { viewKey: "categories" } as GridToolbarProps }} />
            </TableContainer>

            <CategoryTrail primaryKey={trailId} setPrimaryKey={setTrailId} />
        </Fragment>
    );
}