import { Add, Block, Close, Error, MoreHoriz, Nfc, NotStarted, PowerSettingsNew, Search } from '@mui/icons-material';
import { Grid, IconButton, InputAdornment, List, ListItemButton, ListItemText, TextField, Typography } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { trimEnd } from 'lodash';
import moment from 'moment';
import { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LayoutContext, fetchGet, fetchPost, isMobile } from 'wcz-layout';
import { BottomDrawerScanner } from 'wcz-scan';
import StyledCard from '../components/common/StyledCard';
import { DepartmentContext } from '../contexts/DepartmentContext';
import { Defect } from '../models/Defect';
import Device from '../models/Device';
import HomeInfo from '../models/HomeInfo';
import WrittenTag from '../models/WrittenTag';
import { MaintenanceStatus } from '../models/enums/MaintenanceStatus';
import { pmtcUrl } from '../utils/BaseUrl';
import { isNfcAvailable, readNfc } from '../utils/Nfc';

export default function HomePage() {
    const { changeTitle, user, t, snackbar } = useContext(LayoutContext);
    const [hasNfcError, setHasNfcError] = useState<boolean>(false);
    const [searchMaintenance, setSearchMaintenance] = useState<string>("");
    const navigate = useNavigate();
    const { department } = useContext(DepartmentContext);

    const { data = {} as HomeInfo } = useQuery<HomeInfo>(["homepage"], ({ signal }) => fetchGet(`${pmtcUrl}/v1/home${department ? `?department=${trimEnd(department, "0")}` : ""}`, signal), {
        enabled: !!user.department
    });

    useEffect(() => changeTitle("Preventive maintenance"), []);

    useEffect(() => {
        const ctrl = new AbortController();

        if (isNfcAvailable)
            handleReadNfc(ctrl);

        return () => ctrl.abort();
    }, []);

    const handleReadNfc = (ctrl?: AbortController) => {
        readNfc({
            onSuccess: value => onResult(value),
            options: ctrl && { signal: ctrl.signal },
            onError: () => setHasNfcError(true)
        });
    };

    const onResult = useCallback((value: string) => {
        const writtenTag: WrittenTag = JSON.parse(value);
        if (writtenTag.templateTaskId)
            navigate(`/maintenance/task/templateTaskId/${writtenTag.templateTaskId}`);
        else if (writtenTag.defectDescription && writtenTag.id)
            handleCreateDefect(writtenTag.id, writtenTag.defectDescription);
        else if (writtenTag.id)
            navigate(`/maintenance/device/${writtenTag.id}`);
    }, []);

    const handleCreateDefect = useCallback((deviceId: string, defectDescription: string) => {
        fetchGet(`${pmtcUrl}/v1/device/${deviceId}`, undefined).then((device: Device) => {
            createDefect({
                device: device,
                description: defectDescription
            });
        });
    }, []);

    const { mutate: createDefect } = useMutation((req: any) => fetchPost(`${pmtcUrl}/v1/defect`, req), {
        onSuccess: (data: Defect) => {
            snackbar({ message: t("DefectCreated") });
        }
    });

    const handleMaintenanceClick = useCallback((id: string) => () => {
        navigate(`/maintenance/device/${id}`);
    }, []);

    const handleSearchChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => setSearchMaintenance(e.target.value), []);
    const handleSearchClear = useCallback(() => setSearchMaintenance(""), []);

    return (
        <Fragment>
            <Grid container spacing={2} sx={{ px: 2, my: 2 }}>
                {(isNfcAvailable && hasNfcError) && <StyledCard title={t("AllowNfc")} onClick={handleReadNfc}><Nfc fontSize="large" /></StyledCard>}
                <StyledCard title={t("NotStarted")} number={data.notStarted} color="success" onClick={() => navigate("/maintenance/" + MaintenanceStatus.NOT_STARTED)}><NotStarted fontSize="large" color="success" /></StyledCard>
                <StyledCard title={t("InProgress")} number={data.inProgress} color="primary" onClick={() => navigate("/maintenance/" + MaintenanceStatus.IN_PROGRESS)}><MoreHoriz fontSize="large" color="primary" /></StyledCard>
                <StyledCard title={t("OpenDefects")} number={data.unfixedDefects} color="error" onClick={() => navigate("/defects/false")}><Error fontSize="large" color="error" /></StyledCard>
                <StyledCard title={t("ActiveShutdowns")} number={data.activeShutdowns} color="warning" onClick={() => navigate("/shutdowns/false")}><PowerSettingsNew fontSize="large" color="warning" /></StyledCard>
                <StyledCard title={t("DefectAutomate")} color="secondary" onClick={() => navigate("/defect/automate")} info={t("DefectAutomateDescription")!}><Error fontSize="large" color="secondary" /></StyledCard>
                <StyledCard title={t("UnscheduledMaintenance")} color="success" onClick={() => navigate("/maintenance/create")} info={t("UnscheduledMaintenanceDescription")!}><Add fontSize="large" /></StyledCard>
            </Grid>

            {!!data.maintenances?.length &&
                <Grid container sx={{ pr: 2 }} justifyContent="space-between">
                    <Typography variant="h6" sx={{ pl: 1, display: 'flex', alignItems: 'center' }}><Block sx={{ mr: 1 }} /> {t("Maintenances")}</Typography>
                    <TextField variant="standard" value={searchMaintenance} onChange={handleSearchChange} placeholder={t("Search")!}
                        InputProps={{
                            startAdornment: <InputAdornment position="start"><Search /></InputAdornment>,
                            endAdornment: <IconButton size="small" onClick={handleSearchClear}><Close /></IconButton>,
                        }} />
                </Grid>
            }
            <List dense sx={{ mb: isMobile ? 10 : 1 }}>
                {data.maintenances?.filter(m => m.device.name.toLowerCase().includes(searchMaintenance.toLowerCase())).map(maintenance =>
                    <ListItemButton key={maintenance.id} onClick={handleMaintenanceClick(maintenance.device.id)}>
                        <ListItemText primary={maintenance.device.name} secondary={moment(maintenance.createdOn).formatDateTime()} />
                    </ListItemButton>
                )}
            </List>

            {isMobile && <BottomDrawerScanner onResult={onResult} />}
        </Fragment>
    );
}