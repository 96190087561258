import { Add, ArrowDownward, ArrowUpward, Delete, Nfc, QrCode as QrCodeIcon } from "@mui/icons-material";
import { Box, CircularProgress, Dialog, DialogContent, Divider, Fab, Grid, IconButton, TextField, Tooltip, Typography } from "@mui/material";
import { green } from "@mui/material/colors";
import QrCode from 'qrcode.react';
import React, { Fragment, useCallback, useContext, useState } from "react";
import { FileViewer, UploadFile } from 'wcz-file';
import { LayoutContext, isAndroid, isMobile, newGuid } from "wcz-layout";
import TemplateTask from "../../../../models/TemplateTask";
import WrittenTag from "../../../../models/WrittenTag";
import { writeNfc } from "../../../../utils/Nfc";

interface Tag {
    type: "QR" | "NFC",
    taskId: string
}

interface TemplateTasksProps {
    tasks: TemplateTask[],
    setTasks: (tasks: TemplateTask[]) => void,
    page: number;
    setPage: (page: number) => void;
}

export default function TemplateTasks(props: TemplateTasksProps) {
    const { t, snackbar } = useContext(LayoutContext);
    const [tag, setTag] = useState<Tag>({} as Tag);

    const handleOnQuestionChange = (value: string, index: number) => {
        const tasks = props.tasks;
        tasks[index].text = value;
        props.setTasks(tasks);
    };

    const handleOnIndexUp = (index: number) => {
        const tasks = props.tasks;

        const lowerElement = tasks[index - 1];
        const element = tasks[index];

        tasks[index - 1] = element;
        tasks[index] = lowerElement;

        props.setTasks(tasks);
    };

    const handleOnIndexDown = (index: number) => {
        const tasks = props.tasks;

        const higherElement = tasks[index + 1];
        const element = tasks[index];

        tasks[index + 1] = element;
        tasks[index] = higherElement;

        props.setTasks(tasks);
    };

    const handleOnDelete = (index: number) => {
        const tasks = props.tasks;
        tasks.splice(index, 1);
        props.setTasks(tasks);
    };

    const handleOnAdd = () => {
        const tasks = [...props.tasks];
        const newTask: TemplateTask = { id: newGuid(), text: "" };
        tasks.push(newTask);
        props.setTasks(tasks);
        props.setPage(Math.ceil(tasks.length / 10));
    };

    const handleQrPrint = useCallback((taskId: string) => () => setTag({ taskId: taskId, type: "QR" }), []);

    const handleWriteNfc = useCallback((taskId: string) => async () => {
        setTag({ taskId: taskId, type: "NFC" });

        await writeNfc({
            value: JSON.stringify({ templateTaskId: taskId } as WrittenTag),
            onSuccess: () => setTag({} as Tag),
            onError: (err) => { snackbar({ message: err, severity: "error" }); setTag({} as Tag); },
        });
    }, []);

    const nfc = (
        <Box sx={{ display: "flex", height: 300 }}>
            <Box sx={{ m: 'auto', position: 'relative' }}>
                <Tooltip open title={t("AttachNfcTag")} arrow sx={{ position: 'relative' }}>
                    <Fab color="primary">
                        <Nfc />
                    </Fab>
                </Tooltip>
                <CircularProgress size={68} sx={{ color: green[500], position: 'absolute', top: -6, left: -6, zIndex: 1, }} />
            </Box>
        </Box>
    );

    const taskDialog = (
        <Dialog open={!!tag.type} onClose={() => setTag({} as Tag)} fullWidth>
            {tag.type === "QR" &&
                <Fragment>
                    <Typography variant="h6" sx={{ textAlign: 'center', mt: 1 }}>{t("SaveAndPrintTheQrCode")}</Typography>
                    <DialogContent>
                        <QrCode value={JSON.stringify({ id: tag.taskId } as WrittenTag)} size={550} />
                    </DialogContent>
                </Fragment>
            }
            {tag.type === "NFC" && nfc}
        </Dialog>
    );

    return (
        <React.Fragment>
            {props.tasks?.map((task, index) => {

                const startIndex = (props.page - 1) * 10;
                const endIndex = startIndex + 9;

                if (index >= startIndex && index <= endIndex)
                    return (
                        <Grid container sx={{ my: 1 }} key={task.id}>
                            <Grid item md={1} xs={2} sx={{ my: 'auto' }}>
                                {!!index && <IconButton onClick={() => handleOnIndexUp(index)}>
                                    <ArrowUpward />
                                </IconButton>
                                }
                                {index + 1 !== props.tasks.length &&
                                    <IconButton onClick={() => handleOnIndexDown(index)}>
                                        <ArrowDownward />
                                    </IconButton>
                                }
                            </Grid>

                            <Grid item md={11} xs={10}>
                                <Grid item md={12} xs={12}>
                                    <TextField margin="dense" label={t("Task")} fullWidth value={task.text ?? ""} multiline required variant="standard"
                                        onChange={(e) => handleOnQuestionChange(e.target.value, index)} />
                                </Grid>

                                <Grid item md={12} xs={12} sx={{ my: 1 }}>
                                    <UploadFile subId={task.id} />
                                    <FileViewer subId={task.id} />
                                </Grid>
                            </Grid>

                            <Grid item md={12} xs={12} sx={{ display: 'flex', justifyContent: 'end' }}>
                                {!isMobile &&
                                    <IconButton onClick={handleQrPrint(task.id)}>
                                        <QrCodeIcon />
                                    </IconButton>
                                }

                                {isAndroid &&
                                    <IconButton onClick={handleWriteNfc(task.id)}>
                                        <Nfc />
                                    </IconButton>
                                }

                                <Divider orientation="vertical" flexItem sx={{ m: 1 }} />

                                {index + 1 === props.tasks.length &&
                                    <IconButton onClick={handleOnAdd}>
                                        <Add />
                                    </IconButton>
                                }
                                {props.tasks.length !== 1 &&
                                    <IconButton onClick={() => handleOnDelete(index)}>
                                        <Delete />
                                    </IconButton>
                                }
                            </Grid>
                        </Grid>
                    );
                return null;
            })}
            {taskDialog}
        </React.Fragment>
    );
}